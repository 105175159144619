<template>
  <div class="mt-20 w-full  mx-auto text-gray-900 ">
    <div class="p-4 flex">
      <h1 class="text-2xl">Users</h1>
    </div>
    <div class="px-4 py-4 flex justify-left">
      <form>
        <div class="flex mb-4">
          <div class="w-1/2">
            <label> Username </label>
          </div>
          <div class="w-1/2">
            <input
              class="bg-gray-100 rounded border border-black py-1 px-2"
              type="text"
            />
          </div>
        </div>
        <div class="flex mb-4">
          <div class="w-1/2">
            <label> Email </label>
          </div>
          <div class="w-1/2">
            <input
              class="bg-gray-100 rounded border border-black py-1 px-2"
              type="email"
            />
          </div>
        </div>
        <div class="flex mb-4">
          <div class="w-1/2">
            <label> Password </label>
          </div>
          <div class="w-1/2">
            <input
              class="bg-gray-100 rounded border border-black py-1 px-2"
              type="password"
            />
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-1/2">
            <label> User Role </label>
          </div>
          <div class="w-1/2">
            <select class="bg-gray-100 rounded border border-black py-1 px-2">
              <option> Super Admin</option>
              <option> Admin</option>
              <option> Billing</option>
            </select>
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-1/2">
            <button
              style="background-color: #cfb47e"
              class="text-black hover:bg-black hover:text-white px-4 py-1 rounded"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Single User",
  data() {
    return {};
  },
  methods: {},
  components: {}
};
</script>

<style>
</style>